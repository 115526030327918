import { render, staticRenderFns } from "./BoardFilterAmidanLayout.vue?vue&type=template&id=72fb755e&scoped=true&"
import script from "./BoardFilterAmidanLayout.vue?vue&type=script&lang=js&"
export * from "./BoardFilterAmidanLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72fb755e",
  null
  
)

export default component.exports